import React from "react";

import Templates from "../../model/Templates";
import Button from "../global/Button";

import SceneTemplateTopViewDisplay from "./SceneTemplateTopViewDisplay";

export default class SceneTemplateEditor extends React.Component {

    onPreviousTemplate = () => {
        let selected = this.props.selected, templates = Templates.getSceneTemplates(),
            template_id_list = Object.keys(templates), index = template_id_list.indexOf(selected);
        index--;
        if(index < 0){
            index = template_id_list.length - 1;
        }
        this.props.onTemplateSelect(template_id_list[index]);
    };

    onNextTemplate = () => {
        let selected = this.props.selected, templates = Templates.getSceneTemplates(),
            template_id_list = Object.keys(templates), index = template_id_list.indexOf(selected);
        index++;
        if(index >= template_id_list.length){
            index = 0;
        }
        this.props.onTemplateSelect(template_id_list[index]);
    };

    renderTemplateSelector = () => {
        let selected = this.props.selected, template = Templates.getSceneTemplate(selected),
            screen_count = _.get(template, 'data.screens.length', 0),
            multi_screen_count = _.get(template, 'data.multi_screens.length', 0),
            link_count = _.get(template, 'data.links.length', 0);
        return <div>
            <div className="center-align">
                <h4 className="inline-block">Choose your scene template</h4>
            </div>
            <div className="center-align">
                <h5 className="inline-block">{'Template name : ' + template.name}</h5>
            </div>
            <div className="row">
                <div className="col s4 offset-s1">
                    <img style={{width: "100%"}} src={template.preview_url} />
                </div>
                <div className="col s4 offset-s2">
                    <SceneTemplateTopViewDisplay template={template} screen_display_size={{width: 50, height: 15}} link_display_size={{width: 50, height: 15}}
                                                 showAllScreens={true} showAllMultiScreens={true} showAllLinks={true} />
                </div>
            </div>
            <div className="center-align">
                <Button className="t18-btn utils-btn" onClick={this.onPreviousTemplate} style={{height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_left</i>} />
                <Button className="t18-btn utils-btn" onClick={this.onNextTemplate} style={{marginLeft: "50px", height: '50px'}}
                        text={<i className="material-icons" style={{fontSize: '42px', position: 'relative', top: '9px'}}>keyboard_arrow_right</i>} />
            </div>
            <div className="row">
                <div className="col s6 offset-s3 center-align tm">
                    <h5 className="inline-block ">Template details :</h5>
                    {screen_count > 0 ? <h5 className="blue-text">{screen_count + ' screen(s)'}</h5> : undefined}
                    {screen_count > 0 ? <h5 className="green-text">{multi_screen_count + ' multi-screen(s)'}</h5> : undefined}
                    {link_count > 0 ? <h5 className="red-text">{link_count + ' door(s)'}</h5> : undefined}
                </div>
            </div>
        </div>
    };

    render(){
        return <div>
            {this.renderTemplateSelector()}
        </div>
    }

}