import Config from '../config';

import utils from "../utils";
import User from "./user";
import API from "../api";

import generic from "./generic";
import Land from './Land';
import Media from "./Media";
import Scene from './Scene';
import Screen from "./Screen";
import MultiScreen from "./MultiScreen";
import Link from "./Link";
import Templates from "./Templates";
import Currency from "./currency";
import Subscription from "./Subscription";
import SubscriptionToken from "./SubscriptionToken";

const price_decimals = Config.price_decimals;

const model = {

    zones: [
        {number: 1, start: 1, end: 676, price: (80 / price_decimals) + "", public_sale: false},
        {number: 2, start: 677, end: 2704, price: (40 / price_decimals) + "", public_sale: false},
        {number: 3, start: 2705, end: 6084, price: (20 / price_decimals) + "", public_sale: false},
        {number: 4, start: 6085, end: 10816, price: (10 / price_decimals) + "", public_sale: false}
    ],

    land: {},
    scene: {},
    media: {},
    land_template: {},
    scene_template: {},
    screen: {},
    multi_screen: {},
    link: {},
    subscription: {},
    subscription_token: {},

    eth_price: 0,

    initialize: (cb = () => {}) => {
        //return cb();
        model.loadEthPrice(() => {
            let authenticated = User.initialize();
            Land.initialize(model, model.zones, () => {
                Templates.initialize(() => {
                    if(authenticated){
                        return model.loadOwnedEntities(cb);
                    }else{
                        return cb();
                    }
                })
            });
        })
    },

    loadEthPrice: (cb) => {
        API.getEthPrice((data) => {
            model.eth_price = data.eth_price;
            cb();
        }, cb)
    },

    getDollarPrice: (eth_price) => {
        return Math.round(eth_price * model.eth_price);
    },

    loadOwnedEntities: (cb = () => {}) => {
        Media.loadOwnedMedias(() => {
            Scene.loadOwnedScenes(() => {
                Link.loadOwnedLinks(() => {
                    if(!Config.enable_subscription_handling){
                        return cb();
                    }
                    Subscription.loadOwnedSubscriptions(() => {
                        SubscriptionToken.loadOwnedSubscriptionTokens(() => {
                            Currency.loadCurrencies(cb);
                        });
                    });
                });
            })
        });
    },

    loadZones: (data) => {
        console.log('Loading zone data');
        console.log(data);
        for(let i = 0; i < 4; i++){
            let zone_data = data[i];
            let zone = {
                number: parseInt(zone_data.number.toString()),
                start: parseInt(zone_data.start.toString()),
                end: parseInt(zone_data.end.toString()),
                price: (parseFloat(zone_data.price.toString()) / price_decimals) + "",
                public_sale: zone_data.public_sale
            };
            model.zones[i] = zone;
        }
        console.log("Zone data loaded");
    },

    getZoneData: (number) => {
        return model.zones[number - 1];
    },

    loadLandsDataFromTransfers: (events) => {
        console.log('Loading lands data from transfers');
        console.log("Transfer events : " + events.length);
        for(let event of events){
            let land_info = utils.parseLandTransferEvent(event);
            let land = Land.getLandFromTokenId(land_info.tokenId);
            if(land){
                land.owner = land_info.owner;
                land.minted = true;
            }
        }
        generic.onEntityDataUpdate('land');
    },

    onLandTransferEvent: (event) => {
        let land_info = utils.parseLandTransferEvent(event);
        let land = Land.getLandFromTokenId(land_info.tokenId);
        if(land){
            land.owner = land_info.owner;
            land.minted = true;
            land.processing_transaction = false;
            generic.onEntityDataUpdate('land', land.id);
            FlashSales.reloadFlashSaleData().then(() => {
                console.log('Flash sales reloaded');
            });
        }
    },



};

generic.initialize(model);

export default model;

window.model = model;