import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import config from "../../config";
import model from "../../model/model";

import Land from "../../model/Land";
import LandPreviewMap from "../Common/LandPreviewMap";

export default class BuyLandModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openBuyLandModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, undismissible: options.undismissible, close_cb: close_cb, confirm_cb: options.confirm_cb, land_id: options.land_id, price: options.price };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onSubmit = () => {
        this.state.closeModal();
        this.state.confirm_cb();
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let land = Land.getLand(this.state.land_id),
                x = land.position.x,
                y = land.position.y,
                zone = land.zone,
                token_id = land.tokenId,
                price = this.state.price;
            content = <div id="buy-land-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Confirm Land Buying"}</h3>
                <h5>You are about to buy the following land for <span className="theme-color">{price}</span> ETH {config.display_dollar_price ? " (" + model.getDollarPrice(price) + "$)" : ""}</h5>
                <div className="land-info" style={{fontSize: '24px'}}>
                    <div>
                        <span className="bold">Token ID : </span>
                        <span>{token_id}</span>
                    </div>
                    <div>
                        <span className="bold">Zone : </span>
                        <span>{zone}</span>
                    </div>
                    <div>
                        <span className="bold">Position : </span>
                        <span>x = {x}, y = {y}</span>
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <div style={{display: 'inline-block', backgroundColor: 'white', borderRadius: '15px', width: '300px', height: '300px',
                        marginLeft: '40px', verticalAlign: 'top', padding: '32px'}}>
                        <LandPreviewMap x={x} y={y} rotation={0} size={4} colorizeOwnedLands={true} />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button text={'Confirm'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="model-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
