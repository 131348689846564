import React from "react";
import Dev from "../../../model/Dev";
import Button from "../../global/Button";
import Land from "../../../model/Land";
import generic from "../../../model/generic";

import BuildingToolBox from "./BuildingToolBox";
import SceneToolBox from "./SceneToolBox";

const ENABLE_ACCESS_SETUP = false;

export default class extends React.Component {

  state = {selected_view: 'building'};

  componentDidMount() {
      generic.addEntityListener('link', () => {this.forceUpdate()});
  }

  onInitialize = () => {
    window.openLandInitializationForm({land_id: this.props.selected});
  };

  onViewSelect = (view) => {
    this.setState({selected_view: view});
  };

  renderNoSelected = () => {
    return <div style={{textAlign: 'center', marginTop: '50px'}}><h4 style={{display: 'inline-block', color: 'white'}}>No Selected Land</h4></div>;
  };

    renderLinkedLandUnsupported = () => {
        return <div style={{textAlign: 'center', marginTop: '50px'}}><h4 style={{display: 'inline-block', color: 'white'}}>Linked lands management not yet supported</h4></div>;
    }

  renderNotInitialized = () => {
    return <div style={{color: 'white', paddingTop: '30px'}}>
      <div className="center-align"><h4 style={{display: 'inline-block'}}>This land has not been initialized yet.</h4></div>
      <div className="center-align"><Button className="t18-btn tm" text="Initialize" onClick={this.onInitialize} style={{fontSize: '24px'}} /></div>
    </div>;
  };

  render() {
    let land = Land.getLand(this.props.selected), view = this.state.selected_view, scene_id;
    if(land && land.linkedTo){
        land = Land.getLand(land.linkedTo);
    }
    if(!land){
      return this.renderNoSelected();
    }else if(land.linkedTo || land.linkedWith){
        if(!land.initialized){
            return this.renderLinkedLandUnsupported();
        }
    }else if(!land.initialized){
      return this.renderNotInitialized();
    }
    scene_id = Land.getLandSceneId(land.id);
    return <div className={"row"} style={{color: 'white', paddingTop: '30px'}}>
      <div className="center-align">
        <Button className={"view-select-btn" + (view === 'building' ? ' selected': '')} text="Building"
                onClick={this.onViewSelect.bind(null, 'building')} />
        <Button className={"view-select-btn lm" + (view === 'scene' ? ' selected': '')} text="Scene"
                onClick={this.onViewSelect.bind(null, 'scene')} />
      </div>
      {view === 'building' ? <BuildingToolBox selected={this.props.selected} /> : undefined}
      {view === 'scene' && scene_id ? <SceneToolBox selected={scene_id} land_id={land.id} /> : undefined}
    </div>
  }

}
