import React  from 'react';
import _ from 'lodash';

import Land from "../../model/Land";

import Modal from "../global/Modal";
import Button from "../global/Button";
import LandTemplateEditor from "../Common/LandTemplateEditor";
import Templates from "../../model/Templates";


export default class LandBuildingTemplateForm extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
    window.openLandBuildingTemplateForm = this.openModal;
    }

    getOpen = (open) => {
    this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
      let state = {land_id: options.land_id, land_rotation: 0,
          land_template_id: Templates.getDefaultLandTemplate(),
          opened: true, undismissible: options.undismissible, close_cb: close_cb };
      let land = Land.getLand(options.land_id);
      state.land_template_id = land.land_template_id;
      state.land_rotation = _.get(land, 'data.template.rotation', 0);
      this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
    this.setState({ closeModal: close });
    };

    afterOpen = () => {
    //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
    this.setState({opened: false}, this.state.close_cb);
    };

    onLandTemplateSelect = (template_id) => {
        this.setState({land_template_id: template_id});
    };

    onSetLandRotation = (rotation) => {
        this.setState({land_rotation: rotation});
    };

    onSubmit = () => {
      Land.updateLandTemplate(this.state.land_id, this.state.land_template_id, this.state.land_rotation, this.state.closeModal, () => {
          this.state.closeModal();
          window.alertPopup('Error', 'An error has occurred while updating Land building template.');
      })
    };

    render() {
    let disabled = false, content;
    if(this.state.opened) {
        let land = Land.getLand(this.state.land_id), rotation = this.state.land_rotation, template_id = this.state.land_template_id;
        disabled = template_id === land.land_template_id && rotation === _.get(land, 'data.template.rotation', 0);
        content = <div id="land-building-template-form-content" className="reglisse">
            <h3 style={{ margin: 0 }}>{"Land building template"}</h3>
            <div style={{marginTop: '50px'}}>
                <LandTemplateEditor selected={template_id} land={land} rotation={rotation}
                                    onTemplateSelect={this.onLandTemplateSelect} onSetRotation={this.onSetLandRotation} />
            </div>
            <div className="modal-footer">
              <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled}
                      style={{ fontSize: "20px" }}/>
              <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                      style={{ fontSize: "20px" }}/>
            </div>
        </div>;
    }
    return (
      <Modal id="land-building-template-form"
             content={content}
             getOpen={this.getOpen}
             getClose={this.getClose}
             afterOpen={this.afterOpen}
             afterClose={this.afterClose}
             undismissible={this.state.undismissible}
      />
    )
    }
};