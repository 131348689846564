import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import LandTemplateEditor from "../Common/LandTemplateEditor";
import SceneTemplateEditor from "../Common/SceneTemplateEditor";

import Templates from "../../model/Templates";
import Land from "../../model/Land";
import Scene from "../../model/Scene";

export default class LandInitializationForm extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openLandInitializationForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = {land_id: options.land_id, stage: 1, land_name: 'My Land', scene_name: 'My scene', land_rotation: 0,
            land_template_id: Templates.getDefaultLandTemplate().id, scene_template_id: Templates.getDefaultSceneTemplate().id,
            opened: true, undismissible: options.undismissible, close_cb: close_cb };
        if(options.add_scene_floor){
            state.stage = 2;
            state.add_scene_floor = true;
            state.target_scene_id = options.target_scene_id;
        }
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onLandNameChange = (value) => {
        this.setState({land_name: value});
    };

    onNextStage = () => {
        this.setState({stage: this.state.stage + 1});
    };

    onLandTemplateSelect = (template_id) => {
        this.setState({land_template_id: template_id});
    };

    onSetLandRotation = (rotation) => {
        this.setState({land_rotation: rotation});
    };

    onSceneNameChange = (value) => {
        this.setState({scene_name: value});
    };

    onSceneTemplateSelect = (template_id) => {
        this.setState({scene_template_id: template_id});
    };

    onSubmit = () => {
        if(this.state.add_scene_floor){
            Scene.addSceneFloor(this.state.target_scene_id, this.state.scene_name, this.state.scene_template_id, this.state.closeModal, () => {
                this.state.closeModal();
                window.alertPopup('Error', 'An error has occurred while adding scene floor.');
            });
        }else{
            Land.initializeLand(this.state.land_id, {
                name: this.state.land_name,
                template_id: this.state.land_template_id,
                rotation: this.state.land_rotation
            },{
                name: this.state.scene_name,
                template_id: this.state.scene_template_id
            }, this.state.closeModal, () => {
                this.state.closeModal();
                window.alertPopup('Error', 'An error has occurred while initializing Land.');
            })
        }
    };

    renderLandStage = () => {
        let name = this.state.land_name, land = Land.getLand(this.state.land_id);
        return {content: <div>
            <div style={{marginTop: '30px'}}>
                <InputField label={'Land Name'} value={name} onChange={this.onLandNameChange} />
            </div>
            <LandTemplateEditor selected={this.state.land_template_id} land={land} rotation={this.state.land_rotation}
                                onTemplateSelect={this.onLandTemplateSelect} onSetRotation={this.onSetLandRotation} />
        </div>, submit_disabled: name.length === 0};
    };

    renderSceneStage = () => {
        let name = this.state.scene_name;
        return {content: <div>
                <div style={{marginTop: '30px'}}>
                    <InputField label={'Scene Name'} value={name} onChange={this.onSceneNameChange} />
                </div>
                <SceneTemplateEditor selected={this.state.scene_template_id} onTemplateSelect={this.onSceneTemplateSelect} />
            </div>, submit_disabled: name.length === 0, last_stage: true};
    };

    renderCurrentStage = () => {
        if(this.state.stage === 1){
            return this.renderLandStage();
        }else if(this.state.stage === 2){
            return this.renderSceneStage();
        }
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let current_stage = this.renderCurrentStage();
            disabled = current_stage.submit_disabled;
            content = <div id="land-initialization-form-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Land Initialization"}</h3>
                <div>{current_stage.content}</div>
                <div className="modal-footer">
                    <Button text={current_stage.last_stage ? 'Submit' : 'Next'} className='t18-btn' onClick={current_stage.last_stage ? this.onSubmit : this.onNextStage} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="land-initialization-form"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
