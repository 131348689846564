import React  from 'react';
import _ from 'lodash';

import utils from "../../utils";
import Land from "../../model/Land";
import Screen from "../../model/Screen";

import Modal from "../global/Modal";
import Button from "../global/Button";
import LandPreviewMap from "../Common/LandPreviewMap";


export default class LandMediaDisplayPositionModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openLandMediaDisplayPositionModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = { opened: true, land_id: options.land_id, screen_id: options.screen_id, undismissible: options.undismissible, close_cb: close_cb };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let land = Land.getLand(this.state.land_id),
                rotation = _.get(land, 'data.template.rotation', 0),
                screen = Land.getLandEntityData(this.state.land_id, 'screen', this.state.screen_id),
                entries = utils.getSidesFromObjects([screen]);
            content = <div id="land-media-display-position-content" className="reglisse">
                <h3 className="center-align bm" style={{margin: 0}}>{"Building logo position"}</h3>
                <div className="row tm">
                    <div className="col s8 offset-s2 center-align">
                        <LandPreviewMap x={land.position.x} y={land.position.y} highlight_sides={entries} rotation={rotation} size={4} />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button text={"Close"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="land-media-display-position-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
