import React from "react";

import web3 from "../../web3";
import model from "../../model/model";
import Land from "../../model/Land";

import MarketPlaceMap from "./MarketPlaceMap";
import MarketPlaceToolBox from "./MarketPlaceToolBox";
import FlashSaleBanner from "./FlashSaleBanner";
import FlashSales from "../../model/FlashSales";

import bg_4 from '../../images/4.png';
import Config from "../../config";

export default class extends React.Component {

    state = {selected_land: null};

    componentDidMount = () => {
        Land.onLandUpdateSubscribe(this.refresh);
        window.marketPlaceMapSelectLand = (land_id) => {
            let land = Land.getLand(land_id);
            this.onLandSelect(land.position.x, land.position.y);
        };
        window.printSelectedMapLand = () => {
            console.log(Land.getLandFromCoordinates(this.state.selected_land.x, this.state.selected_land.y));
        }
    };

    refresh = () => {
        console.log('refresh land view');
        this.forceUpdate();
    };

    onLandSelect = (x , y) => {
        this.setState({selected_land: {x: x, y: y}}, () => {
            let land = Land.getLandFromCoordinates(x, y);
            if(land){
                Land.refreshLand(land.id);
            }
        });
    };

    onBuySelectedLand = () => {
        let selected = this.state.selected_land, land = Land.getLandFromCoordinates(selected.x, selected.y);
        Land.refreshLand(land.id, (err, data) => {
            if(data.processing_transaction){
                window.alertPopup('Error', 'Error: A transaction is already ongoing for this land');
                return;
            }else if(data.booked){
                window.alertPopup('Error', 'Error: This Land has been booked');
                return;
            }else{
                let zone = model.getZoneData(land.zone),
                    flash_sale = FlashSales.getZoneFlashSaleData(land.zone),
                    price = zone.price;
                if(flash_sale && flash_sale.count > 0){
                    price = flash_sale.price;
                }
                window.openBuyLandModal({land_id: land.id, price: price, confirm_cb: () => {
                    if(flash_sale && flash_sale.count > 0){
                        web3.buyFlashSaleLand(data.id, data.tokenId, price);
                    }else{
                        web3.buyLand(data.id, data.tokenId, price);
                    }
                }})
            }
        });
    };

    render = () => {

        return <div id="land-view"
                    className={"row" + (this.props.show ? '' : ' hide') + (Config.MOBILE_DISPLAY ? ' tm-50': '')}
                    style={{backgroundColor: 'black', borderTop: '2px solid white'}}>
            <div className="col s12 m8" style={{borderRight: '2px solid white', overflow: 'auto', backgroundImage: 'url(' + bg_4 + ')', backgroundSize: 'cover', height: Config.MOBILE_DISPLAY ? undefined : '100%'}}>
                <MarketPlaceMap selected={this.state.selected_land} onSelect={this.onLandSelect}/>
            </div>
            <div className={"col s12 m4" + (Config.MOBILE_DISPLAY ? "" : " h-100")} style={{borderLeft: '2px solid white', marginLeft: '-2px'}}>
                <MarketPlaceToolBox selected={this.state.selected_land} onBuySelectedLand={this.onBuySelectedLand} />
            </div>
        </div>

    }

}