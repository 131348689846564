import React from "react";
import _ from "lodash";
import Config from "../../config";

import TopViewDisplay from "./TopViewDisplay";

export default class SceneTemplateTopViewDisplay extends React.Component {

    getItems = () => {
        let template = this.props.template,
            screens = _.get(template, 'data.screens', []),
            multi_screens = _.get(template, 'data.multi_screens', []),
            links = _.get(template, 'data.links', []),
            items = [];
        if(this.props.showAllScreens){
            for(let screen of screens){
                items.push({type: 'screen', id: screen.id, data: screen});
            }
        }else if(this.props.showScreens){
            for(let screen of this.props.showScreens){
                items.push({type: 'screen', id: screen.id, data: screen});
            }
        }
        if(this.props.showAllMultiScreens){
            for(let multi_screen of multi_screens){
                items.push({type: 'multi_screen', id: multi_screen.id, data: multi_screen});
            }
        }else if(this.props.showMultiScreens){
            for(let multi_screen of this.props.showMultiScreens){
                items.push({type: 'multi_screen', id: multi_screen.id, data: multi_screen});
            }
        }
        if(this.props.showAllLinks){
            for(let link of links){
                items.push({type: 'link', id: link.id, data: link});
            }
        }else if(this.props.showLinks){
            for(let link of this.props.showLinks){
                items.push({type: 'link', id: link.id, data: link});
            }
        }
        return items;
    }

    render(){
        let template = this.props.template,
            items = this.getItems();
        return template ? <TopViewDisplay items={items} background_url={template.top_view_url}
                                        parentWidth={template.width} parentLength={template.length}  /> :
                <img style={{width: '100%', height: '100%'}} src={Config.default_image_placeholder_url} />;
    }

}